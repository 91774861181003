import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../../components/Layout"
import Divider from "../../components/Divider"

import {
  PageContent,
  ImageContainer,
  ContentContainer,
  StyledSocialMedia
} from "./style"

const Page = ({ data, location, pageContext: { index } }) => {
  const {
    mdx: {
      body,
      frontmatter: { name, title, header, image }
    },
    site: {
      siteMetadata: { description }
    }
  } = data

  return (
    <Layout
      location={location}
      title={header}
      seoTitle={name}
      type={index % 2 === 0 ? "secondary" : "tertiary"}
      description={description}
    >
      <PageContent>
        {image?.childImageSharp.fluid && (
          <ImageContainer>
            <Image
              fluid={image.childImageSharp.fluid}
              loading="eager"
              fadeIn={false}
            />
          </ImageContainer>
        )}
        <ContentContainer width={image ? 65 : 100}>
          <h3>{title}</h3>
          <MDXRenderer>{body}</MDXRenderer>
          <StyledSocialMedia align={image ? "left" : "center"} />
        </ContentContainer>
      </PageContent>
      <Divider />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query GetPageByID($id: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      timeToRead
      frontmatter {
        name
        title
        header
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
