import styled from "styled-components"
import SocialMedia from "../../components/SocialMedia"
import media from "../../theme/breakpoints"

export const PageContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8rem;
  font-size: 1.8rem;
  line-height: 2.7rem;

  ${media.md`
    margin-top: 4rem;
    flex-direction: column;
    align-items: center;
  `}
`

export const ImageContainer = styled.div`
  width: 26rem;
  margin-right: 7rem;

  ${media.md`
    margin-right: 0;
    margin-bottom: 3rem;
  `}
`

export const ContentContainer = styled.div`
  flex-basis: ${props => props.width}%;

  ${media.md`
    h3 {
      text-align: center;
    }
  `}
`

export const StyledSocialMedia = styled(SocialMedia)`
  margin-top: 6rem;

  ${media.md`
    text-align: center;
  `}
`
